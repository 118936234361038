import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const About = props => (
  <Layout {...props}>
    <Seo title='About' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header='About Us' />
        <Divider />
        <p>
          Football Today is the world's largest football community delivering
          authentic content in English to more than 100K+ fans globally.
        </p>
        <p>
          We create interesting news about football with our professional team.
        </p>
        <p>Read about Football only with us!</p>
        <p>
          To leave us any comments or questions, please contact us at{' '}
          <span style={{ fontWeight: 'bold' }}>info@football-today.com</span>
        </p>
      </Main>
    </Stack>
  </Layout>
)

export default About
